body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Source Sans Pro',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Container {
  margin: 0 auto;
  max-width: 80%;
}

@media only screen and (min-width: 1024px) {
  .Container {
    max-width: 900px;
  }
}

.Navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0 0;
}

.Navbar .Logo {
  width: 50px;
}

.Navbar .Logo img {
  width: 100%;
}

.LoggedUser {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Panel {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0 0 0;
}

@media only screen and (min-width: 1024px) {
  .Panel {
    flex-wrap: nowrap;
    display: flex;
  }
}

nav {
  width: 100%;
  display: block;
}

nav a {
  margin: 0 15px 0 0;
}

@media only screen and (min-width: 400px) {
  nav {
    display: flex;
  }

  nav a {
    margin: 0 15px 0 0 ;
  }
}

@media only screen and (min-width: 1024px) {
  nav {
    display: block;
    width: auto;
  }
}

.Avatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  background: linear-gradient(310deg, rgba(44,185,120,1) 0%, rgba(131,232,90,1) 100%);
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 35px 0 0;
}

.Avatar .Initials {
  font-weight: 500;
  font-size: .9rem;
}

.NavItem {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  border-radius: .5rem;
  margin: 0 0 15px 0;
  transition: .3s all;
  box-sizing: border-box;
}

.NavItem.NavItem--Active {
  background: rgb(4,123,248);
  background: linear-gradient(39deg, rgba(4,123,248,1) 0%, rgba(0,212,255,1) 100%);
  color: #fff;
  box-shadow: 5px 5px 12px -2px rgba(0,0,0,0.1);
}

.NavItem:hover {
  box-shadow: 5px 5px 12px -2px rgba(0,0,0,0.1);
}

.NavItem__text {
  margin: 0 0 0 15px;
}

a {
  color: inherit;
  text-decoration: none;
}

.Logout {
  display: block;
  cursor: pointer;
  color: rgba(0, 0, 0, .8);
}

.Calendar {
  width: 100%;
  margin: 20px 0 0 0;
}

@media only screen and (min-width: 1024px) {
  .Calendar {
    margin: 0;
    padding: 0 0 0 30px;
  }
}

.leaflet-container {
  margin: 20px 0 0 0;
  height: 300px;
  width: 100%;
  border-radius: .5rem;
}

.CleaningItem {
  width: 100%;
  padding: 15px 25px;
  border-radius: .5rem;
  margin: 0 0 15px 0;
  box-shadow: 0px 0px 15px -2px rgba(0,0,0,.05);
  transition: .3s all;
  box-sizing: border-box;
  overflow: hidden;
}

.CleaningItem .Heading {
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
}

.CleaningItem-Active {
  box-shadow: 0px 0px 15px -2px rgba(0,0,0,.07);
}

.CleaningItem .Heading .Expander {
  background: rgba(0, 0, 0, .03);
  border-radius: .75rem;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: .2s all;
}

.CleaningItem .Heading .Expander:hover svg {
  stroke: rgba(0, 0, 0, .6);
}

.CleaningItem .Heading .Expander svg {
  width: 24px;
  height: 24px;
  stroke: rgba(0, 0, 0, .3);
  transition: .2s all;
}

.CleaningItem-Active .Heading .Expander svg {
  transform: rotate(180deg);
}

.CleaningItem .DiffForHuman {
  text-transform: uppercase;
  font-size: .9rem;
  display: flex;
}

.CleaningItem .DiffForHuman.DiffForHuman--PANIC {
  color: #ff304f;
  font-weight: 700;
}

.CleaningItem .DiffForHuman.DiffForHuman--NEUTRAL {
  color: rgba(4,123,248,1)
}

.CleaningItem .DiffForHuman i {
  margin: 0 5px 0 0;
  --ggs: .6;
}

.CleaningItem .CleaningDetails {
  margin: 20px 0 0 0;
}

.CleaningItem .CleaningDetails .Detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0;
  flex-wrap: wrap;
}

.CleaningItem .CleaningDetails .Detail:last-child {
  border: none;
}

.CleaningItem .CleaningDetails .Detail .DetailName {
  font-size: .9rem;
  color: rgba(0, 0, 0, .2);
  text-transform: uppercase;
  font-weight: 600;
  width: 100%;
}

.CleaningItem .CleaningDetails .Detail .DetailValue {
  margin: 10px 0 0 0;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .CleaningItem .CleaningDetails .Detail {
    flex-wrap: nowrap;
  }

  .CleaningItem .CleaningDetails .Detail .DetailName {
    width: auto;
  }

  .CleaningItem .CleaningDetails .Detail .DetailValue {
    width: auto;
  }
}

.CleaningItem .CleaningDetails .Detail:last-child {
  margin: 0;
}

.CleaningItem .CleaningDetails .Detail.Detail--Block {
  display: block;
}

.CleaningItem .CleaningDetails .Detail.Detail--Block .DetailValue {
  margin: 10px 0 0 0;
}

.IconWrapper {
  position: relative;
}

.IconWrapper .AlertDot {
  position: absolute;
  right: -4px;
  bottom: -6px;
  width: 8px;
  height: 8px;
  line-height: 8px;
  border-radius: 50%;
  background: #ff304f;
}

.LoadingWrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Notifications {
  width: 100%;
  margin: 20px 0 0 0;
}

@media only screen and (min-width: 1024px) {
  .Notifications {
    padding: 0 0 0 30px;
    margin: 0;
  }
}

.NotificationItem {
  position: relative;
  width: 100%;
  padding: 15px 25px;
  border-radius: .5rem;
  margin: 0 0 15px 0;
  box-shadow: 0px 0px 15px -2px rgba(0,0,0,.02);
  transition: .3s all;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.NotificationItem .Message {
  width: 90%;
}

.NotificationItem .DateTime {
  font-size: .9rem;
}

.NotificationItem::before {
  content: "";
  background: linear-gradient(140deg, rgba(202,226,233,0.8239670868347339) 0%, rgba(228,224,238,0.8099614845938375) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 100%;
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem;
}

.NotificationItem.New::before {
  background: linear-gradient(39deg, rgba(4,123,248,1) 0%, rgba(0,212,255,1) 100%);
}

.LoginPage {
  width: 100%;
  height: 100%;
  background-color: rgba(237, 242, 247, 1) !important;
}

.LoginWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoginForm {
  width: 400px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.LoginForm .Logo {
  width: 50px;
  height: auto;
  box-sizing: border-box;
}

.LoginForm .Logo img {
  width: 100%;
  height: auto;
  box-sizing: border-box;
}

.LoginForm .LoginBox {
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06)!important;
  border-radius: .5rem;
  padding: 35px 15px;
  box-sizing: border-box;
  margin: 15px 0 0 0;
}

.LoginForm .Copyrights {
  margin: 15px 0 0 0;
  text-align: center;
  color: rgba(0, 0, 0, .15);
  font-size: .9rem;
  font-weight: 500;
  text-transform: uppercase;
}

.LoginForm .Copyrights a {
  font-weight: 600;
}

.LoginForm .LoginBox input {
  display: block;
  width: 100% !important;
  border: 1px solid #e2e8f0;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
  padding-left: .75rem;
  padding-right: .75rem;
  padding-top: .5rem;
  padding-bottom: .5rem;
  line-height: 1.25;
  border-radius: .25rem;
  color: rgba(74, 85, 104, 1);
  margin: 0 0 10px 0;
  box-sizing: border-box;
}

.LoginForm .LoginBox input.InputError {
  border-color: #ff8383;
  box-shadow: 0 0 0 0.2rem rgba(255, 131, 131,.5)
}

.LoginForm .LoginBox input::placeholder {
  color: rgba(74, 85, 104, .6);
}

.LoginForm .LoginBox input:focus {
  outline: none;
  border-color: rgba(4,123,248,.3);
  box-shadow: 0 0 0 0.2rem rgba(4,123,248,.3)
}

.LoginForm .LoginBox button {
  margin: 15px 0 0 0;
  width: 100%;
  border-radius: .25rem;
  background: linear-gradient(39deg, rgba(4,123,248,1) 0%, rgba(0,212,255,1) 100%);
  color: #fff;
  border: none;
  padding: 15px 20px;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  transition: .3s all;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.1),0 1px 2px 0 rgba(0,0,0,.06);
}

.LoginForm .LoginBox button:hover {
  box-shadow: 0 1px 3px 0 rgba(0,0,0,.3),0 1px 2px 0 rgba(0,0,0,.06);
}

.LoginForm .LoginBox button:focus, .LoginForm .LoginBox button:active  {
  outline: none;
}

.ButtonLoading {
  display: flex;
  justify-content: center;
}

.ButtonLoading div {
  width: 15px !important;
  height: 15px !important;
}

.ButtonLoading svg {
  width: 15px;
}

.LoginError {
  margin: 10px 0;
  color: #ff304f;
  font-weight: 500;
  font-size: .9rem;
}

.PageDesc {
  margin: 40px 0 20px 0;
  color: rgba(0, 0, 0, .4);
  text-transform: uppercase;
  font-size: .9rem;
  letter-spacing: 1.5px;
  display: flex;
  align-items: center;
}

.PageDesc.Today {
  color: #ff304f;
  margin-top: 0;
}

.PageDesc:first-child {
  margin-top: 0;
}

.PageDesc.Today::after {
  border-color: rgba(255, 48, 79, .05);
}

.PageDesc::after {
  content: '';
  border-bottom: 1px solid rgba(0, 0 , 0, .05);
  flex: 1;
  margin: 0 0 0 .25rem;
}

.MapIcon {
  width: 20px;
}

.FamiliarWithCleanings {
  width: 100%;
  margin: 30px 0;
}

.FamiliarWithCleanings button {
  cursor: pointer;
  border-radius: 1.5rem;
  background: rgb(44,185,120);
  background: linear-gradient(29deg, rgba(44,185,120,1) 0%, rgba(131,232,90,1) 100%);
  color: #fff;
  border: none;
  width: 100%;
  padding: 15px 20px;
  transition: .3s all;
  font-size: .9rem;
  letter-spacing: .8px;
}

.FamiliarWithCleanings button:hover {
  -webkit-box-shadow: 0px 0px 21px -8px rgba(44,185,120,1);
  -moz-box-shadow: 0px 0px 21px -8px rgba(44,185,120,1);
  box-shadow: 0px 0px 21px -8px rgba(44,185,120,1);
}

.FamiliarWithCleanings button:active, .FamiliarWithCleanings button:focus {
  outline: none;
}

.FamiliarWithCleanings.Acquainted button {
  cursor: not-allowed;
  opacity: .7;
  filter: grayscale(70%);
}

.Employees-Info {
  background: rgba(0, 0, 0, .02);
  border-radius: .5rem;
  padding: 15px;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail {
  padding: 0;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail:first-child {
  margin: 0 0 10px 0;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail .DetailValue {
  margin: 0;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail.OtherEmployee .DetailName {
  font-size: .85rem;
  color: rgba(0, 0, 0, .7);
  text-transform: none;
  display: flex;
  align-items: center;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail.OtherEmployee .DetailValue {
  font-size: .9rem;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail.OtherEmployee.Header .DetailName {
  font-size: .8rem;
  color: rgba(0, 0, 0, .2);
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: .7px;
  padding: 5px 0 0 0;
  border-top: 1px solid rgba(0, 0, 0, .05);
  margin: 0 0 5px 0;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail.OtherEmployee .DetailName a {
  margin: 0 0 0 10px;
  transition: .2s all;
  display: flex;
  align-items: center;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail.OtherEmployee .DetailName a:hover {
  color: rgba(4, 123, 248, 1);
}

.CleaningItem .CleaningDetails .Employees-Info .Detail.OtherEmployee .DetailName a svg {
  width: 13px;
  height: 13px;
  margin: 0 3px 0 0;
}

.CleaningItem .CleaningDetails .Employees-Info .Detail.OtherEmployee .DetailName a span {
  font-weight: 500;
}